import "./BeaconForm.scss";

import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "../components/forms/Select";
import Button from "../components/Button";
import TextInput from "../components/forms/TextInput";
import { states } from "../data/states";
import TextArea from "../components/forms/TextArea";
import useForm from "../hooks/useForm";
import { useNavigate } from "react-router";
import Panel from "../components/Panel";
import useNotification from "../hooks/useNotification";
import OrgHeader from "../components/navigation/OrgHeader";

import useAuth from "../hooks/useAuth";
import { usePostBeacon } from "../api/beacons";
import { BeaconStatus } from "../constants";

function BeaconForm({ beacon, updateMode }) {
  const [publishing, setPublishing] = useState(false);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { notify } = useNotification();

  const postBeaconMutation = usePostBeacon();
  const connectedUser = auth?.user;

  const submitForm = async (e) => {
    try {
      setPublishing(true);
      const data = new FormData(e.currentTarget);
      data.append("status", BeaconStatus.BEACON_STATUS_PUBLISHED);

      const newBeacon = await postBeaconMutation.mutateAsync(data);
      console.log(newBeacon);
      notify("success", "Your beacon has been published successfully");
      navigate(`/beacons/${newBeacon?.data?.result?.slug}`);
    } catch (error) {
      const errMessage = error?.response?.data?.message;
      notify("error", errMessage);
    } finally {
      setPublishing(false);
    }
  };

  const { formData, handleChange, handleSubmit, validated } = useForm(
    { ...beacon },
    (event) => {
      submitForm(event);
    }
  );

  const colWidth = 10; // used to experiment with bootstrap col width for forms, get rid of this when the ideal width is found

  return (
    <>
      <OrgHeader />
      <h1 className="mb-0 pb-0">
        {updateMode ? "Edit Beacon Draft" : "Create Beacon"}
      </h1>
      <p>
        A Beacon is a Government Agency Opportunity Solicitation to the
        Community. Please read our Community Guidelines regarding Beacon
        Requests or contact{" "}
        <a href="mailto:support@impart.app">support@impart.app</a> for further
        information.
      </p>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="beacon">
        <Row>
          <Col md={colWidth - 2}>
            <TextInput
              id="title"
              label="Title*"
              placeholder="Title"
              name="title"
              onChange={handleChange}
              value={formData.title}
              required
            />
          </Col>
          <Col md={2}>
            <TextInput
              label="Date / Time"
              value={new Date().toLocaleString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}
              readOnly
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col md={colWidth}>
            <TextInput
              label="Solicitation Link*"
              placeholder="Solicitation Link"
              name="solicitation_link"
              onChange={handleChange}
              value={formData.solicitation_link}
              required
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={colWidth}>
            <Panel title="Creator" collapsible>
              <div>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="name"
                      label="Organization Name"
                      name="name"
                      onChange={handleChange}
                      value={connectedUser.organization?.name}
                      required
                      readOnly
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      label="Organization ID"
                      id="id"
                      name="id"
                      onChange={handleChange}
                      value={connectedUser.organization?.slug}
                      validationMessage="ID has to be alphanumeric with no spaces"
                      pattern="[a-zA-Z0-9]+"
                      required
                      readOnly
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="first_name"
                      label="First Name"
                      name="first_name"
                      onChange={handleChange}
                      value={connectedUser.first_name}
                      required
                      readOnly
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="last_name"
                      label="Last Name"
                      name="last_name"
                      onChange={handleChange}
                      value={connectedUser.last_name}
                      required
                      readOnly
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="email"
                      label="Email address"
                      name="email"
                      onChange={handleChange}
                      value={connectedUser.email}
                      required
                      readOnly
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      id="phone"
                      label="Phone Number"
                      name="phone"
                      onChange={handleChange}
                      value={connectedUser.phone}
                      required
                      readOnly
                    />
                  </Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={colWidth}>
            <Panel title="Office Location" collapsible>
              <div>
                <TextInput
                  id="address"
                  label="Address*"
                  placeholder="Address"
                  name="address"
                  onChange={handleChange}
                  value={formData.address}
                  required
                />
                <Row>
                  <Col md={6}>
                    <TextInput
                      id="city"
                      label="City*"
                      placeholder="City"
                      name="city"
                      onChange={handleChange}
                      value={formData.city}
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <Select
                      label="State*"
                      options={states}
                      name="state"
                      onChange={handleChange}
                      value={formData.state || ""}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      label="Zipcode*"
                      placeholder="Zipcode"
                      name="zipcode"
                      onChange={handleChange}
                      value={formData.zipcode}
                      required
                    />
                  </Col>
                  <Col md={6}></Col>
                </Row>
              </div>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col md={colWidth}>
            <TextInput
              label="Webinar Link*"
              placeholder="Webinar Link"
              name="webinar_link"
              onChange={handleChange}
              value={formData.webinar_link}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={colWidth} className="pt-2">
            <TextArea
              label="Details*"
              placeholder="Details"
              name="details"
              onChange={handleChange}
              value={formData.details}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col
            md={colWidth}
            className="d-flex align-content-end flex-row-reverse my-3">
            <Button
              type="submit"
              size="lg"
              variant="primary"
              className="ms-2"
              disabled={!formData.title || postBeaconMutation.isLoading}
              loading={publishing}>
              Publish
            </Button>
            {/* {
              <Button
                type="button"
                size="lg"
                variant="secondary"
                className="ms-2"
                onClick={(e) => saveAsDraft(e)}
                disabled={
                  !formData.title ||
                  postBeaconMutation.isLoading ||
                  editBeaconMutation.isLoading
                }
                loading={savingAsDraft}>
                {updateMode ? "Update draft" : "Save as draft"}
              </Button>
            } */}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default BeaconForm;
