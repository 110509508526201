import "./OpenBeacon.scss";

import React, { useRef } from "react";
import OrgHeader from "../../components/navigation/OrgHeader";
import { Col, Row } from "react-bootstrap";
import OrgBadge from "../../components/OrgBadge";
import ChatPanel from "../../components/ChatPanel";
import Opportunity from "../../components/opportunities/Opportunity";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../../components/ErrorFallback";
import { Link } from "react-router-dom";
import { formatExternalUrl } from "../../utils";

function extractNoticeId(url) {
  const match = url.match(/\/opp\/([a-z0-9]+)\//);
  return match ? match[1] : null;
}

function OpenBeacon({ beacon }) {
  const chatRef = useRef();
  const noticeId = extractNoticeId(beacon.solicitation_link);
  const createdAt = new Date(beacon.created_at);

  const date = createdAt.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  const time = createdAt.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
  });
  return (
    <div className="open-beacon community-request-details">
      <OrgHeader />
      <h1 className="pb-0 mb-0">Beacon Request</h1>

      <div className="community-request-header my-4 p-2 px-4">
        <div className="d-flex flex-column flex-md-row gap-5">
          <div>
            <strong className="community-request-id">
              Beacon ID: <span>{beacon.slug}</span>
            </strong>
            <strong className="campaign-name">
              Campaign Name: <span>{beacon.title} </span>
            </strong>
          </div>
          <div>
            <label>Date</label>
            <span>{date}</span>
          </div>
          <div>
            <label>Time</label>
            <span>{time}</span>
          </div>
        </div>
      </div>

      <Row className="d-flex justify-content-center">
        <Col lg={6} md={12}>
          <div className="creator">
            <OrgBadge
              org={beacon.creator}
              isCreator
              onChatClick={() => {
                window.scrollTo(0, document.body.scrollHeight);
              }}
            />
          </div>
          <Row className="border-top border-bottom">
            <Col>
              <dt>Address</dt>
              <dd>
                {beacon.address}
                <br />
                {beacon.city}, {beacon.state}
                <br />
                {beacon.zipcode}
              </dd>
            </Col>
            <Col>
              <dt>Contact Information</dt>
              <dd>
                P: {beacon.creator.phone}
                <br />
                E: {beacon.creator.email}
              </dd>
            </Col>
          </Row>
        </Col>
        <Col lg={6} md={12}>
          <Row>
            <Col>
              <dt className="mb-2">Details</dt>
              <dd>
                <p>{beacon.details ?? "No details provided."}</p>
              </dd>
            </Col>
          </Row>
        </Col>
      </Row>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Row className="mt-4">
          <Col>
            <Opportunity noticeId={noticeId} />
          </Col>
        </Row>
      </ErrorBoundary>
      <Row>
        <Col className="text-center pt-4">
          <Link
            className="btn btn-primary fs-4"
            to={{ pathname: formatExternalUrl(beacon.webinar_link) }}
            target="_blank">
            Zoom Webinar Call - Sign Up
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <ChatPanel
            title={`Beacon Chat ID ${beacon.slug}`}
            currentChannel={`${beacon.slug}`}
            ref={chatRef}
          />
        </Col>
      </Row>
    </div>
  );
}

export default OpenBeacon;
