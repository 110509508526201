import BeaconsList from "../../components/beacons/BeaconsList";
import OrgHeader from "../../components/navigation/OrgHeader";

function Beacons() {
  return (
    <>
      <OrgHeader />
      <BeaconsList title={`Beacons`} />
    </>
  );
}

export default Beacons;
