import React from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import OpenBeacon from "./OpenBeacon";
import { useFetchBeacon } from "../../api/beacons";

function ViewBeacon() {
  let { slug } = useParams();
  const { isLoading, data: beacon } = useFetchBeacon(slug);
  if (isLoading || !beacon) return <Loading />;
  return <OpenBeacon beacon={beacon} />;
}

export default ViewBeacon;
