import "./BeaconsList.scss";

import React, { useMemo, useState } from "react";
import DataGrid from "../DataGrid";
import OrgBadge from "../OrgBadge";
import { useNavigate } from "react-router-dom";
import { useFetchBeacons } from "../../api/beacons";

function BeaconsList({ title }) {
  const [{ pageIndex }, setPagination] = useState({
    pageIndex: 0,
  });
  const { isLoading, data } = useFetchBeacons(pageIndex);
  const navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        header: "Organization",
        accessorKey: "org",
        cell: ({ cell }) => (
          <>
            <OrgBadge org={cell.row.original.organization} mini />
          </>
        ),
      },
      { header: "Campaign Name", accessorKey: "title" },
      { header: "Address", accessorKey: "complete_address" },
      { header: "Date & Time", accessorKey: "created_at" },
    ],
    []
  );
  return (
    <div className="community-requests-list">
      <DataGrid
        title={title}
        columns={columns}
        isLoading={isLoading}
        data={data?.result?.data ?? []}
        onRowClick={(e, row) => navigate(`/beacons/${row.slug}`)}
        pageIndex={pageIndex}
        setPagination={setPagination}
        pageCount={data?.result?.last_page}
      />
    </div>
  );
}

export default BeaconsList;
