import React from "react";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { formatAddress, formatAssociatedOrgs, formatDate } from "../../utils";
import Loading from "../Loading";
import { Col, Row } from "react-bootstrap";
import {
  useFetchOpportunity,
  useFetchOpportunityDescription,
} from "../../api/opportunities";

function Opportunity({ noticeId }) {
  const { data: opportunity, isLoading: isLoadingOpportunity } =
    useFetchOpportunity(noticeId);
  const { data: descriptionData, isLoading: isLoadingDescription } =
    useFetchOpportunityDescription(opportunity?.description);

  if (!noticeId) {
    return (
      <>
        <h3>Opportunity</h3>
        Opportunity not found.
      </>
    );
  }
  if (isLoadingOpportunity)
    return (
      <>
        <h3>Opportunity</h3>
        <Loading />
      </>
    );
  return (
    <div>
      <h3 className="d-flex align-items-center gap-2">
        Opportunity:{" "}
        <a
          href={opportunity.uiLink}
          target="_blank"
          rel="noreferrer"
          className="d-flex align-items-center gap-2">
          <span>{opportunity.title}</span>
          <span className="fs-5">
            <BoxArrowUpRight />
          </span>
        </a>
      </h3>
      <Row className="py-3">
        <Col>
          <dl>
            <dt>Posted Date</dt>
            <dd>{opportunity.postedDate}</dd>

            {opportunity.placeOfPerformance &&
              Object.keys(opportunity.placeOfPerformance).length > 0 && (
                <>
                  <dt>Place of Performance</dt>
                  <dd>{formatAddress(opportunity.placeOfPerformance)}</dd>
                </>
              )}
          </dl>
        </Col>
        <Col>
          <dl>
            <dt>Response Deadline</dt>
            <dd>{formatDate(opportunity.responseDeadLine)}</dd>
            {opportunity.fullParentPathName &&
              Object.entries(
                formatAssociatedOrgs(opportunity.fullParentPathName)
              ).map(([key, value]) => (
                <React.Fragment key={key}>
                  <dt>{key}</dt>
                  <dd>{value}</dd>
                </React.Fragment>
              ))}
          </dl>
        </Col>
      </Row>
      <h4>Classification</h4>
      <dl>
        {opportunity.typeOfSetAside && (
          <>
            <dt>Set Aside Code</dt>
            <dd>
              {opportunity.typeOfSetAside}
              {"  "}
              <small>{opportunity.typeOfSetAsideDescription}</small>
            </dd>
          </>
        )}

        <dt>Classification Code</dt>
        <dd>{opportunity.classificationCode}</dd>
        <dt>NAICS Code</dt>
        <dd>{opportunity.naicsCode}</dd>
      </dl>

      <h4>Description</h4>
      {isLoadingDescription ? (
        <Loading />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: descriptionData }} />
      )}
    </div>
  );
}

export default Opportunity;
