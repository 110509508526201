import React from "react";
import { useParams } from "react-router-dom";
import Opportunity from "../../components/opportunities/Opportunity";

function ViewOpportunity() {
  const { noticeId } = useParams();
  return <Opportunity noticeId={noticeId} />;
}

export default ViewOpportunity;
