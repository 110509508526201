import { useMutation, useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const usePostBeacon = () => {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (data) => {
      return axiosPrivate.post("/v1/beacons", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
  });
};

const useFetchBeacon = (slug) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["beacons", slug],
    queryFn: () =>
      axiosPrivate.get(`/v1/beacons/${slug}`).then((res) => res.data),
    select: (data) => {
      return data.result;
    },
    useErrorBoundary: true,
    enabled: !!slug,
    cacheTime: 0,
  });
};

const useFetchBeacons = (pageIndex = 0) => {
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: ["beacons", pageIndex],
    queryFn: () =>
      axiosPrivate
        .get(`/v1/beacons?page=${pageIndex + 1}`)
        .then((res) => res.data),
    useErrorBoundary: true,
    cacheTime: 0,
  });
};

export { usePostBeacon, useFetchBeacon, useFetchBeacons };
